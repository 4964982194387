export class GunwallData {
    key: string;
    weaponName: string;
    weaponDesignData?: any[];
    weaponAttachData?: any[];
    avatarURL?: string;
    username: string;
    uid: string;
    name: string;
    url: string;
}
