export class ProfileData {
    key: string;
    uid: string;
    username: string;
    email: string;
    myMarket: number;
    myWall: number;
    myFavourite: number;
    BIO: string;
    avatarURL: string;
}
