export class MarketwallData {
    key: string;
    weaponName: string;
    weaponDesignData?: any[];
    weaponAttachData?: any[];
    uid: string;
    name: string;
    url: string;
    avatarURL?: string;
    username?: string;
    sharecount: number;
    likecount: number;
    likeMembers: string[];
    favouritecount: number;
    favouriteMembers: string[];
}
