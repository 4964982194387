import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.spinner.show();
   
    const token: string = localStorage.getItem("token");
    // const token: string = this.cookies.get('token');
    
    if (token) {
      if (
        request.url.search("/login") === -1 &&
        request.url.search("/socialLogin") === -1
      ) {
        request = request.clone({
          headers: request.headers.set("Authorization", `Bearer ${token}`)
        });
      }
      
    }
    
    if (request.url.indexOf('https://upload.twitter.com/1.1/media/upload.json') !== -1) {
        request = request.clone({
          setHeaders: {
            // 'Content-Type': 'multipart/form-data',
            // 'Accept': 'multipart/form-data',
            // 'Access-Control-Allow-Headers': 'Content-Type',
            'authorization':'OAuth',
            'oauth_consumer_key':'GMZuCbVuuaPTUu3OaUFMvJKAr',
            'oauth_consumer_secret':'CxImVBSMDTBTQN5NaMbrvLCNoqy9KIPzBKHz9rBNVYV0c7irYu'
          }
        });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.spinner.hide();
        }
        // this.spinner.hide();
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        // this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          // console.logs(err);
          switch (err.status) {
            case 400:
              err = err.error;
              break;
            case 401:
              err = err.error;
              this.handleAuthenticationError(err);
              break;
            case 403:
              err = err.error;
              break;
            case 404:
              err = err.error;
              break;
            case 409:
              err = err.error;
              break;
            case 500:
              err = err.error;
              break;
            default:
              break;
          }
        }
        return throwError(err);
      })
    );
  }

  handleAuthenticationError(error: any) {
    // this.common.logout();
  }
}
